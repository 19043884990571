/* eslint-disable camelcase */

import apiAction from '../utilities/CommonMethods';
import * as action from './ActionTypes';
import {
  Base_URL,
  GetConsumptionHistoryURL,
  GetReservations,
  ChangeLogs,
  GetMaterialMovement,
  GetFunctionalLocation,
  GetPlantRelationships,
  GetWarehouse,
  GetPurchasing,
  GetLongText,
  GetClassification,
  GetMaterialChangeLogs,
  Postpone,
  Review,
  Delegate,
  Comments,
  GetMaterialLeadTime,
  GetXPlant,
  GetReturnDocument,
  Inspect,
  MassUpdateChangelog,
  WarehouseMassUpdate,
  IotaMassUpdateComments,
  MassUpdateMrpChangelogRevert,
  MassUpdateMrpChangelog,
  GetWarehouseByPlant,
} from '../APIUrls';
import { COMMENT_TYPE } from '../../components/Common/GlobalConstants';
import { showNotification } from '../utilities/Notifications/NotificationActions';

function materialErrorNotification(plant = '-', material = '-') {
  return {
    Type: 'error',
    Title: 'Material Details',
    Message: `Failed to load data for plant/material: ${plant}/${material}`,
  };
}

const setMaterial = (data) => ({
  type: action.getMaterial,
  payload: data,
});

const fetchMaterial = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}`;
  return apiAction({
    url,
    method: 'GET',
    onSuccess: setMaterial,
    onFailure: () => console.log('Error occured fetching material'),
    onFailureNotification: () => materialErrorNotification(plant, material),
    label: action.getMaterial,
  });
};

const updateReviewData = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}`;
  function updateReview(data) {
    return {
      type: action.updateReviewData,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: updateReview,
    onFailure: (e) => console.log(e),
    label: action.updateReviewData,
  });
};

const fetchMaterialsAsync = async (listOfMaterials, token, dispatch) => {
  const rawResponse = await fetch(`${Base_URL}multiple-materials`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ materials: listOfMaterials }),
  });

  const content = await rawResponse.json();

  const { error, responseData, success } = content;
  if (responseData && success) {
    return responseData;
  } if (error) {
    dispatch(showNotification({
      Type: 'error',
      Title: 'Connection error',
      Message: 'Error occured fetching materials',
    }));
    return [];
  }
  return [];
};

const fetchMaterials = (listOfMaterials) => {
  const url = `${Base_URL}multiple-materials`;

  function setMaterials(data) {
    return {
      type: action.getMaterials,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'POST',
    data: {
      materials: listOfMaterials,
    },
    onSuccess: setMaterials,
    onFailure: () => console.log('Error occured fetching materials'),
    onFailureNotification: () => materialErrorNotification(),
    label: action.getMaterials,
  });
};

const fetchMaterialDescriptions = (listOfMaterials) => {
  const url = `${Base_URL}multiple-material-descriptions`;

  function setMaterials(data) {
    return {
      type: action.getMaterialDescriptions,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'POST',
    data: {
      materials: listOfMaterials,
    },
    onSuccess: setMaterials,
    onFailure: () => console.log('Error occured fetching material descriptions'),
    onFailureNotification: () => materialErrorNotification(),
    label: action.getMaterialDescriptions,
  });
};

const fetchAdditionalMaterial = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}`;
  function setAdditionalMaterial(data) {
    return {
      type: action.getAdditionalMaterial,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setAdditionalMaterial,
    onFailure: () => console.log('Error occured fetching Additional material'),
    onFailureNotification: () => materialErrorNotification(plant, material),
    label: action.getAdditionalMaterial,
  });
};

const fetchConsumptionHistory = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetConsumptionHistoryURL}`;
  function setConsumptionHistory(data) {
    return {
      type: action.getConsumptionHistory,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setConsumptionHistory,
    onFailure: () => console.log('Error occured fetching consumption history'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Consumption history',
      Message: `Failed to load consumption history data for plant/material: ${plant}/${material}`,
    }),
    label: action.getConsumptionHistory,
  });
};

const fetchReservations = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetReservations}`;
  function setReservations(data) {
    return {
      type: action.getReservations,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setReservations,
    onFailure: () => console.log('Error occured fetching reservations'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Reservations',
      Message: `Failed to load reservation data for plant/material: ${plant}/${material}`,
    }),
    label: action.getReservations,
  });
};

const fetchMaterialMovement = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetMaterialMovement}`;
  function setMaterialMovement(data) {
    return {
      type: action.getMaterialMovement,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setMaterialMovement,
    onFailure: () => console.log('Error occured getting material movement'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Material movement',
      Message: `Failed to load material movement data for plant/material: ${plant}/${material}`,
    }),
    label: action.getMaterialMovement,
  });
};

const fetchMaterialLeadTime = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetMaterialLeadTime}`;
  function setMaterialLeadTime(data) {
    return {
      type: action.getMaterialLeadTime,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setMaterialLeadTime,
    onFailure: () => console.log('Error occured fetching leadtime'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'IOTA leadtime',
      Message: `Failed to load leadtime for plant/material: ${plant}/${material}`,
    }),
    label: action.getMaterialLeadTime,
  });
};

const fetchXPlant = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetXPlant}`;
  function setXPlant(data) {
    return {
      type: action.getXPlant,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setXPlant,
    onFailure: () => console.log('Error occured fetching x-plant'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Other plants',
      Message: 'Failed to load other plant data',
    }),
    label: action.getXPlant,
  });
};

const fetchReturnDocument = (plant, material) => {
  const url = `${Base_URL}plant/${plant}/material/${material}/${GetReturnDocument}`;
  function setReturnDocument(data) {
    return {
      type: action.returnDocument,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setReturnDocument,
    onFailure: () => console.log('Error occured fetching return document'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Return document',
      Message: `Failed to load return tab data: ${plant}/${material}`,
    }),
    label: action.returnDocument,
  });
};

const fetchChangelogs = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${ChangeLogs}`;
  function setChangelogs(data) {
    return {
      type: action.getChangelogs,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setChangelogs,
    onFailure: () => console.log('Error occured fetching changelogs'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'IOTA change log',
      Message: `Failed to load change log for plant/material: ${plant}/${material}`,
    }),
    label: action.getChangelogs,
  });
};

const createChangelog = (plant, material, payload) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${ChangeLogs}`;

  function errorNotification(errorMsg) {
    const message = errorMsg.number === 515
      ? 'Received null value where non-null was expected'
      : 'Something went wrong please try again';
    return {
      Type: 'error', Title: 'Changelog Not Created', Message: message,
    };
  }

  return apiAction({
    url,
    method: 'POST',
    data: payload,
    onSuccess: () => fetchChangelogs(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'IOTA Changelog created',
      Message: 'Successfully updated IOTA changelog',
    },
    onFailure: () => console.log('Error occured updating IOTA changelog'),
    onFailureNotification: errorNotification,
    label: action.createChangelog,
  });
};

const fetchMaterialChangelogs = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetMaterialChangeLogs}`;
  function setMaterialChangelogs(data) {
    return {
      type: action.getMaterialChangelogs,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setMaterialChangelogs,
    onFailure: () => console.log('Error occured fetching MRP changelogs'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'MRP changelog',
      Message: `Failed to load MRP change log for material: ${material}`,
    }),
    label: action.getMaterialChangelogs,
  });
};

const fetchFunctionalLocation = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetFunctionalLocation}`;
  function setFunctionalLocation(data) {
    return {
      type: action.getFunctionalLocation,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setFunctionalLocation,
    onFailure: () => console.log('Error occured fetching functional location'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Functional location',
      Message: `Failed to load functional location data for plant/material: ${plant}/${material}`,
    }),
    label: action.getFunctionalLocation,
  });
};

const fetchWarehouse = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetWarehouse}`;
  function setWarehouse(data) {
    return {
      type: action.getWarehouse,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setWarehouse,
    onFailure: () => console.log('Error occured fetching warehouse'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Warehouse',
      Message: `Failed to load warehouse data for plant/material: ${plant}/${material}`,
    }),
    label: action.getWarehouse,
  });
};

const fetchWarehouseByPlant = async (plant, token, dispatch) => {
  const rawResponse = await fetch(`${Base_URL}plants/${plant}/${GetWarehouseByPlant}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const content = await rawResponse.json();

  const { error, responseData, success } = content;

  if (content?.responseData && success) {
    return responseData[0];
  } if (error) {
    dispatch(showNotification({
      Type: 'error',
      Title: 'IOTA DB Connection Failure',
      Message: 'Failed to fetch warehouse number',
    }));
    return null;
  }
  return null;
};

const fetchPurchasing = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetPurchasing}`;
  function setPurchasing(data) {
    return {
      type: action.getPurchasing,
      payload: data,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setPurchasing,
    onFailure: () => console.log('Error occured fetching purchasing'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Purchasing',
      Message: `Failed to load purchasing data for plant/material: ${plant}/${material}`,
    }),
    label: action.getPurchasing,
  });
};

function setPlantRelations(plant, data) {
  return {
    type: action.getPlantRelationships,
    payload: { plant, data },
  };
}

const fetchPlantRelationshipsAsync = async (plant, token, dispatch) => {
  const rawResponse = await fetch(`${Base_URL}plants/${plant}/${GetPlantRelationships}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const content = await rawResponse.json();

  const { error, responseData, success } = content;
  if (responseData && success) {
    return responseData;
  } if (error) {
    dispatch(showNotification({
      Type: 'error',
      Title: 'Plant relationships',
      Message: `Failed to load relationship data for plant: ${plant}`,
    }));
    return [];
  }
  return [];
};

const fetchPlantRelationships = (plant) => {
  const url = `${Base_URL}plants/${plant}/${GetPlantRelationships}`;
  function setPlantRelationships(data) {
    return {
      type: action.getPlantRelationships,
      payload: { plant, data },
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setPlantRelationships,
    onFailure: () => console.log('Error occured fetching plant relationships'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Plant relationships',
      Message: `Failed to load relationship data for plant: ${plant}`,
    }),
    label: action.getPlantRelationships,
  });
};

const getLongText = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${GetLongText}`;
  function storeLongText(data) {
    return {
      type: action.storeLongText,
      payload: { plant, material, data },
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeLongText,
    onFailure: () => console.log('Error occured fetching PO text'),
    label: action.storeLongText,
  });
};

const setClassificationData = (payload) => ({
  type: action.getClassification,
  payload,
});

const getClassification = (material) => {
  const url = `${Base_URL}materials/${material}/${GetClassification}`;
  function setClassification(data) {
    const formattedData = data.reduce((acc, item) => {
      const temp = {};
      temp[item.CHAR_NAME] = item.CHAR_VALUE;
      return { ...acc, ...temp };
    }, { MATERIAL_TYPE_SAP_ID: data[0].MATERIAL_TYPE_SAP_ID });

    return {
      type: action.getClassification,
      payload: formattedData,
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: setClassification,
    onFailure: () => console.log('Error occured fetching classification for material'),
    label: action.getClassification,
  });
};

const createMassUpdateChangelog = (
  changes, changedBy, changedByEmail, textID, plantMaterials,
) => {
  const url = `${Base_URL}${MassUpdateChangelog}`;
  return apiAction({
    url,
    method: 'POST',
    data: {
      changes, changedBy, changedByEmail, textID,
    },
    onSuccess: () => fetchMaterialDescriptions(plantMaterials),
    onFailure: () => console.log(`Error occured creating mass ${textID} changelog`),
    label: action.createMassUpdateChangelog,
  });
};

const addWareHouseComments = (
  data, userID, changedBy, changedByEmail, commentType, plant, materials,
) => {
  const url = `${Base_URL}${WarehouseMassUpdate}`;
  const requestJSON = JSON.stringify(data);
  const plantMaterials = materials.map((m) => ({ plantID: plant, materialID: m }));
  return apiAction({
    url,
    method: 'POST',
    data: {
      commentsTable: data,
      userID,
      changedBy,
      changedByEmail,
      commentType,
    },
    onSuccess: () => createMassUpdateChangelog(
      requestJSON, userID, changedByEmail, 'warehouse', plantMaterials,
    ),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Warehouse memo Update',
      Message: 'Successfully updated Warehouse memo(s) of material(s)',
    },
    onFailure: (e) => console.log(e),
    onFailureNotification: (errorMsg) => ({
      Type: 'error',
      Title: 'Warehouse memo update failed',
      Message: errorMsg,
    }),
    label: action.createMassUpdateComments,
  });
};

const revertChangeLog = (id) => {
  const url = `${Base_URL}${MassUpdateMrpChangelogRevert}`;
  return apiAction({
    url,
    method: 'POST',
    data: id,
    onSuccessNotification: {
      Type: 'success',
      Title: 'IOTA Changelogs reverted',
      Message: 'Successfully reverted IOTA changelogs and impactforms',
    },
    onFailure: (err) => {
      console.log('Error occured reverting mass MRP changelogs and impartform');
    },
    label: action.createMassMRPUpdateChangelog,
  });
};

const createMassMrpChangelog = (changes, revert) => {
  const url = `${Base_URL}${MassUpdateMrpChangelog}`;

  const storeGroupChangeId = (data) => {
    if (data?.length > 0) {
      const { id } = data[0];
      return {
        type: action.changeGroupIdFromMassUpdateMRP,
        payload: id,
      };
    }
  };

  return apiAction({
    url,
    method: 'POST',
    data: { changes, isReverting: !!revert },
    onSuccessNotification: {
      Type: 'success',
      Title: revert ? 'RPA Changelogs created' : 'IOTA Changelogs created',
      Message: revert ? 'Successfully updated RPA changelogs' : 'Successfully updated IOTA changelogs',
    },
    onSuccess: (data) => storeGroupChangeId(data),
    onFailure: () => console.log('Error occured creating mass MRP changelogs'),
    label: action.createMassMRPUpdateChangelog,
  });
};

const addIotaMemoComments = (
  data, userID, changedBy, changedByEmail, commentType, plant, materials,
) => {
  const url = `${Base_URL}${IotaMassUpdateComments}`;
  const requestJSON = JSON.stringify(data);
  const plantMaterials = materials.map((m) => ({ plantID: plant, materialID: m }));
  return apiAction({
    url,
    method: 'POST',
    data: {
      commentsTable: data,
      userID,
      changedBy,
      changedByEmail,
      commentType,
    },
    onSuccess: () => createMassUpdateChangelog(
      requestJSON, userID, changedByEmail, 'iota-memo', plantMaterials,
    ),
    onSuccessNotification: {
      Type: 'success',
      Title: 'IOTA memo Update',
      Message: 'Successfully updated IOTA memo(s) of material(s)',
    },
    onFailure: (e) => console.log(e),
    onFailureNotification: (errorMsg) => ({
      Type: 'error',
      Title: 'Iota memo update failed',
      Message: errorMsg,
    }),
    label: action.createMassUpdateComments,
  });
};

const postpone = (plant, material, date, userEmail, userID) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Postpone}`;
  const setPostponeComplete = () => ({
    type: action.setActionComplete,
    payload: 'postpone',
  });
  const data = {
    PlantID: plant,
    MaterialID: material,
    PostponeToDate: date,
    PostponedBy: userEmail,
    UserID: userID,
  };
  return apiAction({
    url,
    method: 'POST',
    data,
    onSuccess: setPostponeComplete,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Postponed successfully',
      Message: 'Successfully postponed handling of material',
    },
    onFailure: () => console.log('Error occured postponing'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Postpone',
      Message: `Failed to postpone ${plant}/${material}`,
    }),
    label: action.postpone,
  });
};

const inspect = (plant, material, date, userEmail, userID) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Inspect}`;
  const setInspectComplete = () => ({
    type: action.setActionComplete,
    payload: 'inspect',
  });
  const data = {
    PlantID: plant,
    MaterialID: material,
    InspectToDate: date,
    InspectedBy: userEmail,
    UserID: userID,
  };
  return apiAction({
    url,
    method: 'POST',
    data,
    onSuccess: setInspectComplete,
    onSuccessNotification: {
      Type: 'success',
      Title: 'Inspected successfully',
      Message: 'Successfully inspected handling of material',
    },
    onFailure: () => console.log('Error occured inspecting material'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Inspected',
      Message: `Failed to inspected ${plant}/${material}`,
    }),
    label: action.inspect,
  });
};

const clearPostponement = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Postpone}`;
  const data = {
    PlantID: plant,
    MaterialID: material,
  };
  return apiAction({
    url,
    method: 'DELETE',
    data,
    onSuccess: () => fetchMaterial(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Removed postponement',
      Message: 'Successfully removed postponed handling of material',
    },
    onFailure: () => console.log('Error occured clearing postponement'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Clear postponement',
      Message: `Failed to clear postponement for ${plant}/${material}`,
    }),
    label: action.clearPostponement,
  });
};

const clearReview = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Review}`;
  const data = {
    PlantID: plant,
    MaterialID: material,
  };
  return apiAction({
    url,
    method: 'DELETE',
    data,
    onSuccess: () => fetchMaterial(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Removed review',
      Message: 'Successfully removed postponed handling of material',
    },
    onFailure: () => console.log('Error occured clearing review'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Clear review',
      Message: `Failed to clear review for ${plant}/${material}`,
    }),
    label: action.clearReview,
  });
};

const clearInspection = (plant, material) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Inspect}`;
  const data = {
    PlantID: plant,
    MaterialID: material,
  };
  return apiAction({
    url,
    method: 'DELETE',
    data,
    onSuccess: () => fetchMaterial(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Removed inspection',
      Message: 'Successfully removed inspection handling of material',
    },
    onFailure: () => console.log('Error occured clearing inspection'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Clear inspection',
      Message: `Failed to clear inspection for ${plant}/${material}`,
    }),
    label: action.clearInspection,
  });
};

const review = (plant, material, userEmail) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Review}`;
  const data = {
    PlantID: plant,
    MaterialID: material,
    ReviewedBy: userEmail,
  };
  return apiAction({
    url,
    method: 'POST',
    data,
    onSuccess: () => ({ type: action.setActionComplete, payload: 'review' }),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Reviewed',
      Message: 'Successfully marked material as reviewed',
    },
    onFailure: () => console.log('Error occured setting material as reviewed'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Review',
      Message: `Failed to set ${plant}/${material} as reviewed`,
    }),
    label: action.review,
  });
};

const getComments = (plant, material, type) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Comments}/${type}`;
  function storeIOTAComments(data) {
    return {
      type: type === COMMENT_TYPE.IOTA ? action.storeIOTAComments : action.storeWarehouseComments,
      payload: {
        plant, material, data,
      },
    };
  }

  return apiAction({
    url,
    method: 'GET',
    onSuccess: storeIOTAComments,
    onFailure: () => console.log(`Error occured fetching comments for plant/material ${plant}/${material}`),
    label: action.storeIOTAComments,
  });
};

const addComment = (UserID, UserEmail, UserName, plant, material, Content, type) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Comments}/${type}`;

  return apiAction({
    url,
    method: 'POST',
    data: {
      UserID,
      UserEmail,
      UserName,
      Content,
    },
    onSuccess: () => fetchMaterial(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Updated successfully',
      Message: `Successfully updated comment ${type}`,
    },
    onFailure: () => console.log(`Error occured adding comments for plant/material ${plant}/${material}`),
    label: action.addComment,
  });
};

const deleteComment = (ID, plant, material, type) => {
  const url = `${Base_URL}${Comments}/${type}`;

  return apiAction({
    url,
    method: 'DELETE',
    data: {
      ID,
      plant,
      material,
    },
    onSuccess: () => getComments(plant, material, type),
    onFailure: () => console.log(`Error occured when deleting comment for plant/material ${plant}/${material}`),
    label: action.deleteComment,
  });
};

const adminDeleteComment = (ID, UserID, plant, material, type) => {
  const url = `${Base_URL}${Comments}/${type}/admin`;

  return apiAction({
    url,
    method: 'DELETE',
    data: {
      ID,
      UserID,
      plant,
      material,
    },
    onSuccess: () => getComments(plant, material, type),
    onFailure: () => console.log(`Error occured when deleting comment for plant/material ${plant}/${material}`),
    label: action.adminDeleteComment,
  });
};

const delegate = (plant, material, userEmail, arg) => {
  const url = `${Base_URL}plants/${plant}/materials/${material}/${Delegate}`;
  const data = {
    PlantID: plant,
    MaterialID: material,
    ChangedBy: userEmail,
    Automatic: arg === 'Automatic',
    CoreTeam: arg === 'Core Team',
    ManualMRP: arg === 'Manual MRP',
  };
  return apiAction({
    url,
    method: 'POST',
    data,
    onSuccess: () => fetchMaterial(plant, material),
    onSuccessNotification: {
      Type: 'success',
      Title: 'Delegated successfully',
      Message: 'Successfully delegated handling of material',
    },
    onFailure: () => console.log('Error occured delegating handling of material'),
    onFailureNotification: () => ({
      Type: 'error',
      Title: 'Delegation error',
      Message: `Failed to delegate handling of ${plant}/${material}`,
    }),
    label: action.delegate,
  });
};

const clearActionCompleteStatus = (payload) => ({ type: action.clearActionComplete, payload });

const setLoading = (loadingKey) => ({
  type: action.setLoading,
  payload: loadingKey,
});

const clearData = () => ({
  type: action.clearData,
  payload: null,
});

export default {
  setMaterial,
  fetchMaterial,
  fetchMaterials,
  fetchMaterialDescriptions,
  fetchAdditionalMaterial,
  fetchMaterialsAsync,
  fetchConsumptionHistory,
  fetchReservations,
  createChangelog,
  fetchChangelogs,
  fetchMaterialLeadTime,
  fetchXPlant,
  fetchReturnDocument,
  fetchMaterialMovement,
  fetchMaterialChangelogs,
  fetchFunctionalLocation,
  fetchWarehouse,
  fetchPurchasing,
  setPlantRelations,
  fetchPlantRelationships,
  fetchPlantRelationshipsAsync,
  setLoading,
  getLongText,
  setClassificationData,
  getClassification,
  addWareHouseComments,
  addIotaMemoComments,
  postpone,
  clearPostponement,
  inspect,
  clearInspection,
  review,
  clearReview,
  delegate,
  clearActionCompleteStatus,
  clearData,
  updateReviewData,
  addComment,
  deleteComment,
  adminDeleteComment,
  getComments,
  revertChangeLog,
  createMassMrpChangelog,
  fetchWarehouseByPlant,
};
