import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import 'antd/dist/antd.css';
import PropTypes from 'prop-types';
import allActions from '../../../actions';
import './MaterialListView.css';
import MaterialTableHeader from './MaterialTableHeader';
import MaterialTableFooter from './MaterialTableFooter';
import MaterialList from './MaterialList';
import Loading from '../../Common/Loading';
import {
  generateHeaders, createSearchFilterItem, modes, getConvertedPriceFilters,
  getColDataTypes,
} from './MaterialListFunctions';
import { USE_WORKLIST_VIEW, NUMBER_OF_RECORDS_TO_FETCH, listModes } from '../../Common/GlobalConstants';
import hasAuthenticatedRole from '../../Common/AuthFunctions';
import { appInsights } from '../../../api/appInsights';
import { columnsWithAggregation } from '../../Common/SpecialValues';
import SelectedItemsContext from '../../../contexts/selectedItemsContext';

const MaterialListContainer = ({
  worklistID,
  worklistDetails,
  headerCells,
  searchFilters,
  sortColumn,
  sortDirection,
  mode,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const materialListColumns = useSelector((state) => state.materialState.materialListColumns);
  const displayFetchedMaterials = useSelector((state) => state.materialState.materialListData);
  const staticWLData = useSelector((state) => state.worklistState.staticWorklist);
  const headerCellsStore = useSelector((state) => state.materialState.displayHeaderCells);
  const selectedRowKeys = useSelector((state) => state.materialState.selectedRowKeys);
  const [displayHeaderCells, setDisplayHeaderCells] = useState([]);
  const [allHeaderCells, setAllHeaderCells] = useState([]);
  const commonStateStore = useSelector((state) => state.commonState);
  const userSettings = useSelector((state) => state.commonState.userSettings);
  const userState = useSelector((state) => state.authState.user);
  const materialViewStore = useSelector((state) => state.materialState.localMaterialViewStore);
  const dropdownValues = useSelector((state) => state.materialState.dropdownValues);
  const checkMatListCount = useSelector((state) => state.materialState.checkMatListCount);
  const prevPath = useSelector((state) => state.materialState.prevPath);
  const { resetSelectedItems, updateSelected } = useContext(SelectedItemsContext);

  const [materialViewState, setMaterialViewState] = useState({
    headerCells,
    sortColumn: sortColumn || materialViewStore?.sortColumn,
    sortDirection: sortDirection || materialViewStore?.sortDirection,
    searchFilters: searchFilters || materialViewStore?.searchFilters,
    scrollPage: {
      currentNoOfRecords: 0,
      noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
    },
  });
  const [allMatsInContractSearch, setAllMaterialsInContract] = useState(false);
  const [atEndOfList, setAtEndOfList] = useState(false);
  const endOfResults = useSelector(
    (state) => state.materialState.localMaterialViewStore?.atEndOfResults,
  );
  const useGlobalDefault = useSelector(
    (state) => state.commonState.userSettings?.USE_DEFAULTS_GLOBALLY,
  );
  const useDefaultSettings = useSelector((state) => state.commonState.useDefaultSettings);
  const materialLoading = useSelector((state) => state.materialState.isLoadingData);
  const [readyToFetch, setReadyToFetch] = useState(false);
  const [requireFetch, setRequireFetch] = useState(false);
  const userSettingsLoaded = useSelector((state) => state.commonState.loaded?.userSettings);
  const userViewsLoaded = useSelector((state) => state.materialState.loaded?.userViews);
  const dropdownLoaded = useSelector((state) => state.materialState.loaded?.dropdownValues);
  const matListColumnsLoaded = useSelector(
    (state) => state.materialState.loaded?.materialListColumns,
  );
  const materiallistCount = useSelector((state) => state.materialState.materiallistSearchCount);
  const reset = useSelector((state) => state.materialState.reset);
  const selectedView = useSelector((state) => state.materialState.selectedView);
  const userViews = useSelector((state) => state.materialState.userViews);
  const defaultCurrency = useSelector((state) => state.commonState.userSettings?.DEFAULT_CURRENCY);
  const currencyList = useSelector((state) => state.commonState.currencyList);
  const selectedWidgetView = useSelector((state) => state.commonState.selectedWidgetView);
  const [headersResolved, setHeadersResolved] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [canViewPrices, setCanViewPrices] = useState(false);
  const [allowRowClick, setAllowRowClick] = useState(true);
  const [hasSelectedAll, setHasSelectedAll] = useState(false);
  const [hasSorted, setHasSorted] = useState(false);
  const [isCopying, setCopying] = useState(false);
  const displayHeaderRef = useRef(displayHeaderCells);
  const defaultCurrencyRef = useRef(null);

  useEffect(() => {
    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      resetSelectedItems();
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    if (userState?.uniqueId && !userViewsLoaded) {
      dispatch(allActions.MaterialListActions.getUserViews(
        userState.uniqueId, userState.account?.username,
      ));
    }
  }, [userState?.uniqueId]);

  useEffect(() => {
    if (userState?.idTokenClaims?.roles) {
      const userCanViewPrices = hasAuthenticatedRole(
        userState?.idTokenClaims?.roles, process.env.REACT_APP_VIEW_PRICES,
      );
      setCanViewPrices(userCanViewPrices);
    }
  }, [userState?.idTokenClaims]);

  useEffect(() => {
    if (checkMatListCount) {
      if (materiallistCount?.[0]?.NoofRecords
        && materiallistCount?.[0]?.NoofRecords < displayFetchedMaterials?.length) {
        dispatch(allActions.MaterialListActions.clearMaterialList());
      }
      dispatch(allActions.MaterialListActions.setCheckMatlistCount(false));
    }
  }, [checkMatListCount]);

  const appendDefaultFilters = (currentFilters) => {
    const filters = currentFilters ?? {};
    // avoid overwriting plant filter if in worklist mode and the worklist has a plant filter
    if (useDefaultSettings && !(worklistID && searchFilters?.PLANT_FACILITY_SAP_ID)) {
      if (userSettings?.DEFAULT_PLANTS?.length > 0) {
        filters.PLANT_FACILITY_SAP_ID = [
          {
            ColumnName: 'PLANT_FACILITY_SAP_ID',
            FilterOperator: 'EqualTo',
            FilterValue: userSettings?.DEFAULT_PLANTS,
          },
        ];
      } else if (userSettings?.DEFAULT_COMPANY_CODES?.length > 0) {
        filters.COMPANY_CODE = [
          {
            ColumnName: 'COMPANY_CODE',
            FilterOperator: 'EqualTo',
            FilterValue: userSettings?.DEFAULT_COMPANY_CODES,
          },
        ];
      }
    }
    if (!filters.MATERIAL_TYPE_DELETION_IND) {
      filters.MATERIAL_TYPE_DELETION_IND = [
        {
          ColumnName: 'MATERIAL_TYPE_DELETION_IND',
          FilterOperator: 'EqualTo',
          FilterValue: ['0'],
        },
      ];
    }
    return { ...filters };
  };

  const changeSearchFilters = (filterValue) => {
    setMaterialViewState({
      ...materialViewState,
      searchFilters: appendDefaultFilters(filterValue),
      scrollPage: {
        currentNoOfRecords: 0,
        noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
      },
    });
  };

  useEffect(() => {
    if (userSettingsLoaded
      && userViewsLoaded
      && matListColumnsLoaded
      && materialViewState
      && !readyToFetch) {
      if (useDefaultSettings && !worklistID) {
        changeSearchFilters(materialViewState.searchFilters);
      }
      if (userSettings?.DEFAULT_USER_VIEW && !selectedView) {
        dispatch(allActions.MaterialListActions.selectView(userSettings.DEFAULT_USER_VIEW));
      }
      setHeadersResolved(true);
    }
  }, [userSettingsLoaded, matListColumnsLoaded, materialViewState, userViewsLoaded]);

  const hasPrevPath = () => {
    if ((history.location?.state?.prevPath === 'matDetails' || prevPath === 'matDetails')
      && materialLoading === false) {
      return true;
    }
    return false;
  };

  const fetchMaterials = (noResetSelectedItems) => {
    if (mode === modes.STATIC) {
      const offset = staticWLData?.length ?? 0;
      dispatch(allActions.WorkListActions.getStaticWL(
        worklistID,
        materialViewState.sortColumn,
        materialViewState.sortDirection,
        materialViewState.scrollPage.noOfRecords,
        hasSorted ? 0 : offset,
        listModes.matList,
      ));
      if (selectedItems?.length > 0 && offset === 0 && !noResetSelectedItems) {
        setSelectedItems([]);
        dispatch(allActions.MaterialListActions.setSelectedRowKeys([]));
      }
    } else if (!hasPrevPath()) {
      const newSearchID = uuidv4();
      dispatch(allActions.MaterialListActions.setLoading());
      dispatch(allActions.MaterialListActions.setSearchID(newSearchID));
      const newMatListFilters = getConvertedPriceFilters(
        materialViewState, defaultCurrency, currencyList,
      );
      /*
        User can turn on the option of seeing all materials in a contract,
        but this needs to be turned off if they aren't searching in a contract anymore
      */
      const filteringOnContract = !!newMatListFilters?.searchFilters?.CONTRACT_NUMBER;
      if (!filteringOnContract) setAllMaterialsInContract(false);
      const getAllMaterials = filteringOnContract && allMatsInContractSearch;
      const dataTypes = getColDataTypes(newMatListFilters?.searchFilters, materialListColumns);
      dispatch(allActions.MaterialListActions.fetchMaterialList(
        newMatListFilters,
        newSearchID,
        getAllMaterials,
        dataTypes,
        commonStateStore.SQLOnly?.matList,
      ));
      if (materialViewState.scrollPage.currentNoOfRecords === 0) {
        dispatch(allActions.MaterialListActions.setMaterialListCountNull());
        const specialCols = ['CONTRACT_NUMBER', 'STORAGE_BIN', 'FACILITY_SAP_ID'];
        if (specialCols.find((x) => newMatListFilters.searchFilters[x])
           || commonStateStore.SQLOnly?.matList) {
          dispatch(allActions.MaterialListActions.fetchMaterialListCount(
            newMatListFilters, newSearchID, getAllMaterials,
          ));
        }
      }
      if (selectedItems?.length > 0 && materialViewState.scrollPage.currentNoOfRecords === 0 && !noResetSelectedItems) {
        setSelectedItems([]);
        dispatch(allActions.MaterialListActions.setSelectedRowKeys([]));
      }
    } else {
      const state = { ...history.location.state };
      delete state.prevPath;
      history.replace({ ...history.location, state });
      dispatch(allActions.MaterialListActions.deletePrevPath());
    }
    setHasSorted(false);
  };

  const updateDisplayHeaderCells = (newDisplayHeaderCells) => {
    displayHeaderRef.current = newDisplayHeaderCells;
    setDisplayHeaderCells(newDisplayHeaderCells);
  };

  useEffect(() => {
    if (headersResolved) {
      if (headerCellsStore?.length > 0 && !worklistID) {
        updateDisplayHeaderCells(headerCellsStore);
      }
      setReadyToFetch(true);
    }
  }, [headersResolved]);

  useEffect(() => {
    setRequireFetch(true);
    if (materialViewState) {
      dispatch(allActions.MaterialListActions.setLocalMaterialView(materialViewState));
    }
    dispatch(allActions.MaterialListActions.clearColAggregations());
  }, [materialViewState]);

  const getColumnData = (changedView) => {
    // avoid applying stored column setup if in worklist mode
    if (headerCellsStore && !changedView && (!worklistID || hasPrevPath())) return headerCellsStore;
    if (selectedView && selectedView !== USE_WORKLIST_VIEW) {
      return userViews.find((v) => v.VIEW_ID === selectedView)?.columns;
    }
    if (selectedView === USE_WORKLIST_VIEW && headerCells.length > 0) return headerCells;
    return null;
  };

  const updateWidth = (columnName, newWidth) => {
    const tempDisplayHeaderCells = [...displayHeaderRef.current];
    const i = tempDisplayHeaderCells.findIndex((x) => x.dataIndex === columnName);
    if (i !== -1) {
      tempDisplayHeaderCells[i] = {
        ...tempDisplayHeaderCells[i],
        width: newWidth,
      };
      updateDisplayHeaderCells(tempDisplayHeaderCells);
    }
  };

  const updateFilters = (filters, usingAdvanced) => {
    const updatedFilters = _.cloneDeep(materialViewState.searchFilters);
    const displayFilters = _.cloneDeep(displayHeaderCells);
    if (usingAdvanced) {
      for (let i = 0; i < displayFilters.length; i++) {
        displayFilters[i].filteredValue = null;
        displayFilters[i].filterDropdown = null;
      }
      setMaterialViewState({
        ...materialViewState,
        searchFilters: appendDefaultFilters(filters),
        scrollPage: {
          currentNoOfRecords: 0,
          noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
        },
      });
      updateDisplayHeaderCells(displayFilters);
    } else {
      Object.keys(filters).forEach((filter) => {
        for (let i = 0; i < displayHeaderCells.length; i++) {
          if (filter === displayHeaderCells[i].dataIndex) {
            if (filters[filter]?.length) {
              displayFilters[i].filteredValue = filters[filter];
              updatedFilters[filter] = [{
                ColumnName: filter, FilterValue: filters[filter], FilterOperator: 'Contains', fromTable: true,
              }];
            } else {
              delete updatedFilters[filter];
              delete displayFilters[i].filteredValue;
            }
          }
        }
      });
      changeSearchFilters(updatedFilters);
    }
  };

  const onChangeFilterSort = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      setSelectedItems([]);
      if (sorter.order !== undefined) {
        if (mode === modes.STATIC) setHasSorted(true);
        setMaterialViewState({
          ...materialViewState,
          sortColumn: sorter.field,
          sortDirection: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          scrollPage: {
            currentNoOfRecords: 0,
            noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
          },
        });
      } else {
        setMaterialViewState({
          ...materialViewState,
          sortColumn: '',
          sortDirection: '',
          scrollPage: {
            currentNoOfRecords: 0,
            noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
          },
        });
      }
    }
    if (extra.action === 'search') {
      changeSearchFilters(filters);
    }
    if (extra.action === 'aggregate') {
      const newMatListFilters = getConvertedPriceFilters(
        materialViewState, defaultCurrency, currencyList,
      );
      const filteringOnContract = !!newMatListFilters?.searchFilters?.CONTRACT_NUMBER;
      if (!filteringOnContract) setAllMaterialsInContract(false);
      const getAllMaterials = filteringOnContract && allMatsInContractSearch;
      const newSearchID = uuidv4();
      dispatch(allActions.MaterialListActions.setColAggrLoading(sorter?.field));
      dispatch(allActions.MaterialListActions.fetchMaterialListColAggregate(
        newMatListFilters, newSearchID, getAllMaterials, sorter?.field,
      ));
    }
  };

  const getSummaryForCurrentView = () => {
    const colList = displayHeaderCells?.filter(
      (x) => x?.dataIndex && columnsWithAggregation.includes(x.dataIndex),
    )?.map((x) => x.dataIndex);
    onChangeFilterSort(
      {},
      materialViewState?.searchFilters,
      { field: colList },
      { action: 'aggregate' },
    );
  };

  const updateHeaders = (changedView, currentCurrency) => {
    let currency = {};
    if (currentCurrency) {
      const temp = currencyList?.find((x) => currentCurrency === x?.FROM_CURRENCY);
      currency = { ...temp, defaultCurrency: currentCurrency };
    }
    if (readyToFetch) {
      const generatedHeaders = generateHeaders(
        materialListColumns,
        dropdownValues,
        getColumnData(changedView),
        materialViewState.searchFilters,
        mode,
        updateWidth,
        onChangeFilterSort,
        useDefaultSettings,
        canViewPrices,
        currency,
        false,
        !!worklistID,
      );
      updateDisplayHeaderCells(generatedHeaders.tempHeaderCells);
      setAllHeaderCells(generatedHeaders.tempAllCells);
    }
  };

  useEffect(() => {
    if (dropdownLoaded && matListColumnsLoaded && readyToFetch) {
      updateHeaders(null, defaultCurrency);
    }
  }, [materialViewState?.searchFilters,
    materialViewState?.sortColumn,
    materialViewState?.sortDirection,
    readyToFetch,
    dropdownValues,
    materialListColumns,
  ]);

  const updateLocally = () => {
    updateHeaders(true, defaultCurrency);
  };

  useEffect(() => {
    updateLocally();
  }, [selectedView, defaultCurrency]);

  useEffect(() => {
    defaultCurrencyRef.current = defaultCurrency;
  }, [defaultCurrency]);

  useEffect(() => {
    if (selectedWidgetView && readyToFetch) {
      dispatch(allActions.MaterialListActions.selectView(selectedWidgetView));
      dispatch(allActions.CommonActions.selectWidgetView(null));
    }
  }, [selectedWidgetView, readyToFetch]);

  useEffect(() => {
    if (reset) {
      const newSearchFilters = appendDefaultFilters({});
      setMaterialViewState({
        ...materialViewState,
        searchFilters: newSearchFilters,
        sortColumn: undefined,
        sortDirection: undefined,
        scrollPage: {
          currentNoOfRecords: 0,
          noOfRecordsToFetch: NUMBER_OF_RECORDS_TO_FETCH,
        },
      });
      if (userSettings?.DEFAULT_USER_VIEW) {
        dispatch(allActions.MaterialListActions.selectView(userSettings.DEFAULT_USER_VIEW));
      } else {
        dispatch(allActions.MaterialListActions.selectView(null));
      }
      dispatch(allActions.MaterialListActions.setSelectedRowKeys([]));
      dispatch(allActions.MaterialListActions.resetComplete());
    }
  }, [reset]);

  useEffect(() => {
    if (requireFetch && readyToFetch) {
      fetchMaterials();
      setRequireFetch(false);
    }
  }, [requireFetch, readyToFetch]);

  useEffect(() => {
    if (materialViewState) {
      const newFilters = materialViewState.searchFilters
        ? _.cloneDeep(materialViewState.searchFilters)
        : {};
      // added readyToFetch here to avoid deleting plant-filters when loading after returning from
      // material details or possibly other page.
      // plant filter should not be overwritten if in worklist state
      // and the worklist has a plant filter.
      if (readyToFetch && !(worklistID && searchFilters?.PLANT_FACILITY_SAP_ID)) {
        delete newFilters?.PLANT_FACILITY_SAP_ID;
        delete newFilters?.COMPANY_CODE;
      }
      changeSearchFilters(newFilters);
    }
  }, [useDefaultSettings]);

  useEffect(() => {
    if (commonStateStore.quickSearchObject) {
      // coming from quick search, all previous path is irrelevant
      try {
        dispatch(allActions.MaterialListActions.deletePrevPath());
        const state = { ...history.location.state };
        delete state.prevPath;
        history.replace({ ...history.location, state });

        const {
          quickSearchText,
          searchMMR,
          selectedPlants,
          selectedCCs,
          funcLocations,
          storageBins,
          contractNos,
          searchOperators,
          allMaterialsInContract,
        } = commonStateStore.quickSearchObject;
        const filters = {};
        let quicksearchFilter;
        if (quickSearchText.length > 0) {
          const operator = quickSearchText.some((text) => text.includes('*')) ? 'WildCard' : searchOperators?.keywords;
          const searchText = quickSearchText.map((text) => text.replaceAll('*', '%'));
          quicksearchFilter = createSearchFilterItem('QUICK_SEARCH', operator, searchText, true);
        }
        const searchMMRfilter = searchMMR.length > 0 ? createSearchFilterItem('MATERIAL_TYPE_SAP_ID', searchOperators?.material, searchMMR) : null;
        const plantFilter = selectedPlants.length > 0 ? createSearchFilterItem('PLANT_FACILITY_SAP_ID', searchOperators?.plant, selectedPlants) : null;
        const ccFilter = selectedCCs.length > 0 ? createSearchFilterItem('COMPANY_CODE', searchOperators?.companyCode, selectedCCs) : null;
        const funcLocationFilter = funcLocations.length > 0 ? createSearchFilterItem('FACILITY_SAP_ID', searchOperators?.funcLoc, funcLocations) : null;
        const storageBinFilter = storageBins.length > 0 ? createSearchFilterItem('STORAGE_BIN', searchOperators?.storageBin, storageBins) : null;
        const contractNosFilter = contractNos.length > 0 ? createSearchFilterItem('CONTRACT_NUMBER', searchOperators?.contractNos, contractNos) : null;

        Object.assign(filters, quicksearchFilter);
        Object.assign(filters, searchMMRfilter);
        Object.assign(filters, plantFilter);
        Object.assign(filters, ccFilter);
        Object.assign(filters, funcLocationFilter);
        Object.assign(filters, storageBinFilter);
        Object.assign(filters, contractNosFilter);
        changeSearchFilters(filters);
        dispatch(allActions.CommonActions.setQuickSearchNull());
        setAllMaterialsInContract(allMaterialsInContract);
      } catch (error) {
        appInsights.trackException({
          error,
          properties: {
            component: 'Quick Search in Material List',
          },
        });
      }
    } else if (commonStateStore.mmSearchObject) {
      changeSearchFilters(commonStateStore.mmSearchObject);
      dispatch(allActions.CommonActions.clearPlantLvlMatView());
    }
  }, [commonStateStore.quickSearchObject, commonStateStore.mmSearchObject]);

  useEffect(() => {
    if (userState?.uniqueId) {
      setAtEndOfList(false);
      dispatch(allActions.WorkListActions.getStaticWLs(
        userState?.uniqueId, listModes.matList,
      ));
    }
  }, [displayFetchedMaterials, staticWLData, userState]);

  useEffect(() => {
    if (displayHeaderCells?.length > 0) {
      dispatch(allActions.MaterialListActions.setDisplayHeaderCells(displayHeaderCells));
    }
  }, [displayHeaderCells]);

  const endOfList = () => {
    if (!endOfResults) {
      // logic for end of list
      const currentNoOfRecords = (materialViewState.scrollPage.currentNoOfRecords
        + NUMBER_OF_RECORDS_TO_FETCH);
      const noRowsToFetch = materialViewState.scrollPage.noOfRecordsToFetch;
      setMaterialViewState({
        ...materialViewState,
        scrollPage: {
          currentNoOfRecords,
          noOfRecordsToFetch: noRowsToFetch,
        },
      });
    }
  };

  useEffect(() => {
    if (atEndOfList) {
      if (mode === modes.STATIC) {
        if (worklistDetails.NoOfRecords <= staticWLData.length) return;
      } else if (materiallistCount?.[0]?.NoofRecords
        && displayFetchedMaterials?.length
        && displayFetchedMaterials.length === materiallistCount[0].NoofRecords) return;
      endOfList();
    }
  }, [atEndOfList]);

  const rowSelection = {
    onChange: (_selectedRowKeys, userSelectedRows) => {
      const plantMaterials = userSelectedRows.map((x) => (
        { plantID: x.PLANT_FACILITY_SAP_ID, materialID: x.MATERIAL_TYPE_SAP_ID }
      ));
      setSelectedItems(plantMaterials);
      updateSelected(plantMaterials);
      if (userSelectedRows?.length > selectedItems?.length + 1) setHasSelectedAll(true);
      else {
        setHasSelectedAll(false);
      }
      const sorter = (a, b, res) => {
        if (Number.isNaN(a) || Number.isNaN(b)) return 0;
        if (a === b) return res;
        return a - b;
      };
      const sortedRowKeys = _selectedRowKeys.sort((a, b) => {
        const sortRes = sorter(Number.parseInt(a?.slice(0, 4), 10),
          Number.parseInt(b?.slice(0, 4), 10),
          2);
        if (sortRes === 2) {
          return sorter(Number.parseInt(a?.slice(4), 10), Number.parseInt(b?.slice(4), 10), 0);
        }
        return sortRes;
      });
      dispatch(allActions.MaterialListActions.setSelectedRowKeys(sortedRowKeys));
    },
    selectedRowKeys,
    fixed: true,
    columnWidth: '50px',
  };

  const handleRowClick = (plant, material, e) => {
    if (allowRowClick && !isCopying) {
      if (e?.target?.cellIndex === 0) return;
      const scrollY = document.querySelector('.ant-table-body')?.scrollTop;
      dispatch(allActions.MaterialListActions.setScrollToMaterial(scrollY));
      history.push(`/material-details/${plant}/${material}`, { mode, worklistID });
    }
  };

  const tableMatProps = {
    title: () => (
      <MaterialTableHeader
        setDisplayHeaderCells={updateDisplayHeaderCells}
        worklistID={worklistID}
        worklistDetails={worklistDetails}
        userName={userState?.account?.name}
        updateFilters={updateFilters}
        displayHeaderCells={displayHeaderRef.current}
        allHeaderCells={allHeaderCells}
        useGlobalDefault={useGlobalDefault}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        mode={mode}
        worklistHasView={headerCells?.length > 0}
        displayData={displayFetchedMaterials}
        materialViewState={materialViewState}
        setAllowRowClick={setAllowRowClick}
        setMaterialViewState={setMaterialViewState}
        canViewPrices={canViewPrices}
        searchFilters={materialViewStore?.searchFilters}
        materiallistCount={materiallistCount}
        materialMaster={false}
        updateLocally={updateLocally}
        hasSelectedAll={hasSelectedAll}
        defaultCurrency={defaultCurrency}
        currencyList={currencyList}
        getSummaryForCurrentView={getSummaryForCurrentView}
        updateCallback={() => fetchMaterials(true)}
      />
    ),
    footer: () => (
      <MaterialTableFooter
        mode={mode}
        noOfRecords={worklistDetails?.NoOfRecords}
        data={displayFetchedMaterials}
        listCount={materiallistCount}
        staticWLCount={staticWLData?.length}
      />
    ),
    scroll: { y: window.innerHeight - 280 },
    pagination: false,
  };

  return (
    displayHeaderCells.length > 0
      ? (
        <MaterialList
          additionalTableProps={tableMatProps}
          displayHeaderCells={displayHeaderRef.current}
          setDisplayHeaderCells={updateDisplayHeaderCells}
          handleRowClick={handleRowClick}
          data={mode === modes.STATIC ? staticWLData : displayFetchedMaterials}
          loading={mode === modes.STATIC ? false : materialLoading}
          onChange={onChangeFilterSort}
          setAtEndOfList={setAtEndOfList}
          rowSelection={rowSelection}
          setCopying={setCopying}
          defaultCurrency={defaultCurrency}
          currencyList={currencyList}
        />
      )
      : <Loading text="Loading Material List" />);
};

MaterialListContainer.defaultProps = {
  worklistID: '',
  worklistDetails: {},
  headerCells: [],
  searchFilters: null,
  sortColumn: null,
  sortDirection: null,
  mode: '',
};

MaterialListContainer.propTypes = {
  worklistID: PropTypes.string,
  worklistDetails: PropTypes.shape({
    WorklistID: PropTypes.string,
    WorklistName: PropTypes.string,
    WorklistDescription: PropTypes.string,
    CreatedDate: PropTypes.string,
    ModifiedDate: PropTypes.string,
    CreatedBy: PropTypes.string,
    ModifiedBy: PropTypes.string,
    IsWorklistShared: PropTypes.bool,
    CreatedUserEmail: PropTypes.string,
    ModifiedByUserEmail: PropTypes.string,
    NoOfRecords: PropTypes.number,
  }),
  headerCells: PropTypes.arrayOf(PropTypes.any),
  searchFilters: PropTypes.objectOf(PropTypes.any),
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(modes)),
};

export default MaterialListContainer;
